import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import { _convertCMSFile } from '@/helper/manageParameter'
import { slideImageAnimate } from '@/helper/framer-motion/animate'
import { imageSlide } from '@/helper/framer-motion/variant'
import HeroSliderContent from './HeroSliderContent'
import { isMobile } from '@/helper/checkDeviceMedia'

const HeroSlider = ({
   images = [],
   currentImageIndex = -1,
   direction = 0,
   extraClassDescription = '',
}) => {
   const slideDetail = images[currentImageIndex]
   const bgImageSlides = isMobile()
      ? _convertCMSFile(
           slideDetail.attributes.mobile_thumbnail.data.attributes.url || ''
        )
      : _convertCMSFile(
           slideDetail.attributes.thumbnail.data.attributes.url || ''
        )

   return (
      <div
         className="slides"
         style={{
            backgroundImage: `url(${bgImageSlides})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
         }}>
         <div className="slide">
            <AnimatePresence initial={false} custom={direction}>
               {!_.isEmpty(slideDetail) &&
               !_.isEmpty(slideDetail.attributes.thumbnail.data.attributes) ? (
                  <motion.img
                     key={'slide-image-' + slideDetail.id}
                     src={_convertCMSFile(
                        slideDetail.attributes.thumbnail.data.attributes.url ||
                           ''
                     )}
                     alt={
                        slideDetail.attributes.thumbnail.data.attributes
                           .alternativeText ||
                        'GlobalXtreme Internet Service Provider'
                     }
                     className={
                        'slide-image ' +
                        (!_.isEmpty(
                           slideDetail.attributes.mobile_thumbnail.data
                              .attributes
                        ) &&
                        !_.isEmpty(
                           slideDetail.attributes.mobile_thumbnail.data
                              .attributes.url
                        )
                           ? 'd-none d-md-block'
                           : 'd-block')
                     }
                     {...slideImageAnimate(imageSlide, direction)}
                  />
               ) : null}

               {!_.isEmpty(slideDetail) &&
               !_.isEmpty(
                  slideDetail.attributes.mobile_thumbnail.data.attributes
               ) ? (
                  <motion.img
                     key={'slide-image-mobile-' + slideDetail.id}
                     src={_convertCMSFile(
                        slideDetail.attributes.mobile_thumbnail.data.attributes
                           .url || ''
                     )}
                     alt={
                        slideDetail.attributes.mobile_thumbnail.data.attributes
                           .alternativeText ||
                        'GlobalXtreme Internet Service Provider'
                     }
                     className="slide-image-mobile d-block d-md-none"
                     {...slideImageAnimate(imageSlide, direction)}
                  />
               ) : null}
            </AnimatePresence>

            {/*<div className="img-linear-background" />*/}

            <HeroSliderContent
               slideDetail={slideDetail}
               direction={direction}
               extraClassDescription={extraClassDescription}
            />
         </div>
      </div>
   )
}

export default HeroSlider
