import { useState, useEffect } from 'react'
import { wrap } from 'popmotion'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import HeroSlider from '../general/heroSlider/HeroSlider'
import HeroSliderControl from '../general/heroSlider/HeroSliderControl'

gsap.registerPlugin(ScrollTrigger)

const HeadPageSlider = ({ content = {}, extraClassDescription = '' }) => {
   const [[page, direction], setPage] = useState([0, 0])

   const currentImageIndex = wrap(0, content.length, page)

   const _handlePaginate = (newDirection = 0) => {
      setPage([page + newDirection, newDirection])
   }

   const _handleNext = () => _handlePaginate(1)

   const _handlePrev = () => _handlePaginate(-1)

   const _handleClickIndicator = (index = -1) => {
      if (index < 0 || index === currentImageIndex) return

      const newDirection = index > currentImageIndex ? 1 : -1
      setPage([index, newDirection])
   }

   useEffect(() => {
      const interval = setInterval(() => {
         const nextIndex = (currentImageIndex + 1) % content.length
         _handleClickIndicator(nextIndex)
      }, 8000)

      return () => clearInterval(interval)
   }, [currentImageIndex])

   return (
      <section className="section-head-of-page bg-dark-300" data-scroll-section>
         <HeroSlider
            images={content}
            currentImageIndex={currentImageIndex}
            direction={direction}
            extraClassDescription={extraClassDescription}
         />

         {content.length > 1 ? (
            <HeroSliderControl
               images={content}
               currentImageIndex={currentImageIndex}
               actions={{
                  prev: _handlePrev,
                  next: _handleNext,
                  click: _handleClickIndicator,
               }}
            />
         ) : null}
      </section>
   )
}

export default HeadPageSlider
