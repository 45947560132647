import { slideRightAnimate } from '@/helper/framer-motion/animate'
import { slideUp } from '@/helper/framer-motion/variant'
import { AnimatePresence, motion } from 'framer-motion'

const HeroSliderControl = ({
   images = [],
   currentImageIndex = 0,
   dynamicMainBullets = 3,
   actions = { prev: () => {}, next: () => {}, click: () => {} },
}) => {
   const totalImages = images.length

   const calculateVisibleBullets = () => {
      const halfRange = Math.floor(dynamicMainBullets / 2)
      let start = Math.max(currentImageIndex - halfRange, 0)
      let end = Math.min(start + dynamicMainBullets, totalImages)

      if (end - start < dynamicMainBullets) {
         start = Math.max(end - dynamicMainBullets, 0)
      }

      return { start, end }
   }

   const { start, end } = calculateVisibleBullets()

   return (
      <AnimatePresence mode="wait">
         <motion.div
            className="slideshow-control"
            {...slideRightAnimate(slideUp(0.4))}>
            {images.map((vm, index) => {
               const isActive = currentImageIndex === index
               const isVisible = index >= start && index < end

               if (!isVisible) return null

               return (
                  <button
                     key={vm.id}
                     type="button"
                     onClick={() => actions.click(index)}
                     className={isActive ? 'active' : ''}
                  />
               )
            })}
         </motion.div>
      </AnimatePresence>
   )
}

export default HeroSliderControl
