import { AnimatePresence, motion } from 'framer-motion'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { slideRightAnimate } from '@/helper/framer-motion/animate'
import { hideThenShow, slideRight } from '@/helper/framer-motion/variant'
import { BtnLinkPrimaryArrow } from '../Button'

const HeroSliderContent = ({
   slideDetail = {},
   direction = 0,
   extraClassDescription = '',
}) => {
   return (
      <AnimatePresence custom={direction} mode="wait">
         <div key={'slide-text-' + slideDetail.id} className="container">
            <div className="row">
               <div className="col-md-6">
                  <div className="hero-text-wrapper">
                     <motion.h1
                        role="pre"
                        key={'title-' + slideDetail.id}
                        className="hero-text-transform mt-4 mb-3 py-md-4 an-hero-title"
                        {...(direction === 0
                           ? slideRightAnimate(hideThenShow(0.2), direction)
                           : slideRightAnimate(slideRight(0.6), direction))}>
                        {slideDetail.attributes.title}
                     </motion.h1>

                     {slideDetail.attributes.description ? (
                        <div className="hero-desc mb-0">
                           <motion.div
                              key={'desc-one-' + slideDetail.id}
                              className={extraClassDescription}
                              {...slideRightAnimate(
                                 slideRight(0.7),
                                 direction
                              )}>
                              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                 {slideDetail.attributes.description}
                              </ReactMarkdown>
                           </motion.div>
                        </div>
                     ) : null}

                     {slideDetail.attributes.link ? (
                        <div className="row">
                           <div className="col-md-6">
                              <motion.div
                                 key={'button-' + slideDetail.id}
                                 className="mt-5"
                                 {...slideRightAnimate(
                                    slideRight(0.9),
                                    direction
                                 )}>
                                 <BtnLinkPrimaryArrow
                                    name={slideDetail.attributes.linkName}
                                    href={slideDetail.attributes.link}
                                    extraClass="fw-500 mobile-fs-14"
                                 />
                              </motion.div>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </div>
            </div>
         </div>
      </AnimatePresence>
   )
}

export default HeroSliderContent
