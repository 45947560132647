import { easeIn, easeInOut, easeOut } from 'framer-motion'

export const slideRight = (delay) => {
   return {
      hidden: (direction) => {
         return {
            opacity: 0,
            x: direction === 0 ? 0 : direction > 0 ? 100 : -50,
            y: direction === 0 ? 50 : 0,
         }
      },
      show: (direction) => {
         return {
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
               duration: direction === 0 ? 0.75 : 0.5,
               delay: direction === 0 ? 0 : delay,
               ease: easeInOut,
            },
         }
      },
      exit: (direction) => {
         return {
            opacity: 0,
            x: direction < 0 ? 100 : -100,
            y: 0,
            transition: {
               duration: 0.3,
               ease: easeInOut,
            },
         }
      },
   }
}

export const slideUp = (delay) => {
   return {
      hidden: {
         opacity: 0,
         y: 50,
         x: '-50%',
      },
      show: {
         opacity: 1,
         y: 0,
         x: '-50%',
         transition: {
            duration: 0.5,
            delay,
            ease: easeInOut,
         },
      },
      exit: {
         opacity: 0,
         y: 0,
         x: 0,
      },
   }
}

export const hideThenShow = (delay) => {
   return {
      hidden: {
         opacity: 0,
      },
      show: {
         opacity: 1,
         transition: {
            duration: 0.3,
            delay,
            ease: easeInOut,
         },
      },
      exit: (direction) => {
         return {
            opacity: 0,
            x: direction < 0 ? 100 : -100,
            transition: {
               duration: 0.3,
               ease: easeInOut,
            },
         }
      },
   }
}

export const imageSlide = {
   initial: (direction) => {
      return {
         x: direction > 0 ? '100vw' : '-100vw',
         opacity: 0,
      }
   },
   enter: {
      x: 0,
      opacity: 1,
      transition: {
         duration: 0.75,
         delay: 0.2,
         ease: easeInOut,
      },
      transitionEnd: {
         x: 0,
      },
   },
   exit: (direction) => {
      return {
         x: direction < 0 ? '100vw' : '-100vw',
         opacity: 0.6,
         transition: {
            duration: 0.75,
            delay: 0.2,
            ease: direction ? easeIn : easeOut,
         },
      }
   },
}
